import React from 'react';
import './App.css';

function App() {
  const logoStyle = {
    width: '45px', // Adjust the width as needed
    height: '47px', // Adjust the height as needed
    marginTop: '20px', // Add margin to create space between text and image
  };

  const launchingStyle = {
    color: '#379099',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'italic',
    fontSize: '30px',
    fontWeight: 250,
    marginRight: '5px', // Add margin to create space between text and image
  };

  const soonStyle = {
    color: '#A0B35D',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'italic',
    fontSize: '30px',
    fontWeight: 250,
    marginRight: '5px',
  };

  return (
    <div className="App">
      <header className="App-header">
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <span style={launchingStyle}>launching</span>
          <span style={soonStyle}>Soon</span>
          <img src="icon1.gif" style={logoStyle} className="App-logo App-logo-launching" alt="logo" />
        </p>
      </header>
    </div>
  );
}

export default App;
